@import "../../sass/formatting.scss";
@import "../../sass/inputs.scss";

.AssignedCard {
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 1px 0 0 rgba($color: #000000, $alpha: 0.05);

  .labelsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 4px;

    .Label {
      margin-right: 4px;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 800;
      color: white;
    }
  }

  .cardBody {
    width: 100%;
    height: auto;
    vertical-align: top;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .inWhere {
        font-size: 12px;
        color: gray;
        text-align: left;

        span {
          color: black;
          text-decoration: underline;
        }

        select {
          border: none;
          background: none;
          text-decoration: underline;
          margin: 0 4px 8px 4px;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 0.01px;
          text-overflow: "";
          font-size: 10px;

          &:focus {
            outline: none;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .cardName {
        height: auto;
        width: 100%;
        border-radius: 2px;
        border: none;
        color: black;
        font-size: 14px;
        resize: none;

        &::placeholder {
          color: black;
          font-size: 14px;
        }

        &:hover {
          cursor: pointer;
          background-color: white;
        }

        &:focus {
          background-color: white;
        }
      }
    }
  }
}
