@import "../../sass/inputs.scss";

.ReturnButton {
  @include menuButton(dark);
  width: auto;
  font-size: 14px;
  background-color: rgba($color: #000000, $alpha: 0.05);
  margin: 4px 12px;
  padding-left: 16px;
  padding-right: 16px;
  color: #0e1c33;
  height: 100%;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.15);
  }

  &::before {
    content: none;
  }
}
