@import "../../sass/inputs.scss";

.ProfileMenu_container {
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;

  .ProfileMenu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    background-color: white;
    box-shadow: 0 0 12px rgba($color: #000000, $alpha: 0.2);
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    margin-top: 0;
    overflow: scroll;

    .menuHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-left: 20px;

      h3 {
        text-align: right;
        width: 100%;
        padding: 0;
      }
      .exitButton {
        @include exitButton(2em);
        margin: 0 8px;
      }
    }
    hr {
      width: 100%;
      height: 1px;
      background-color: gray;
      border: none;
      margin: 0;
    }

    .menu_li {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: right;
      height: 48px;
      padding: 16px 20px;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
        cursor: pointer;
      }
    }
  }
}
