.LabelsMenu {
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.05);
  padding: 12px 6px;

  .labelForm {
    margin-bottom: 4px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 2px;

    .labelFormTextInput {
      background: transparent;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 700;
      padding-left: 8px;
      margin-left: 10px;
      margin-right: 20px;
      border-radius: 2px;
      height: 75%;

      &:hover {
        cursor: pointer;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
      }

      &:focus {
        outline: none;
      }
    }

    .submitButton {
      height: 75%;
      width: 80px;
      border: none;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
      color: rgba($color: #000000, $alpha: 0.8);
      border-radius: 2px;
      font-size: 12px;
      margin-right: 8px;

      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.5);
        cursor: pointer;
      }
    }
  }
}
