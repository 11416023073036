@import "../../sass/formatting.scss";
@import "../../sass/inputs.scss";

.CardLabels {
  position: absolute;
  top: -100px;
  width: 300px;
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 12px 6px rgba($color: #000000, $alpha: 0.09);
  border-radius: 2px;
  padding: 0 8px 8px 8px;
  list-style: none;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .cardLabelsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .exitButton {
      @include exitButton();
    }
  }

  .cardLabelsForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .saveButton {
      background-color: #61be4f;
      color: white;
      padding: 16px;
      width: 136px;
      border: none;
      border-radius: 2px;
      font-weight: 700;
      align-self: flex-end;
      margin-top: 20px;

      &:hover {
        background-color: #6ac957;
        cursor: pointer;
      }
    }

    .checkboxLabel {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 36px;
      width: 100%;
      margin-bottom: 4px;
      padding: 0px;
      color: white;
      font-weight: 600;
      font-size: 16px;
      justify-content: flex-start;

      .checkboxInput {
        opacity: 0;
        height: 0px;
        width: 0px;
        &:checked ~ .checkboxCustom {
          margin-top: 6px;

          &::before {
            content: "\02714";
            color: black;
            opacity: 0.3;
            padding: 4px;
          }
        }
      }

      .checkboxCustom {
        width: 24px;
        height: 24px;
        background: transparent;
      }
    }
  }
}
