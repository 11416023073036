@import "../../sass/formatting.scss";
@import "../../sass/inputs.scss";

.Board {
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .boardHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 8px 16px;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba($color: #000000, $alpha: 0.1);

    .boardName_form {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 4px 0;

      .boardName {
        height: 32px;
        max-width: 400px;
        border-radius: 4px;
        border: none;
        padding: 0 8px;
        color: #162b4d;
        font-size: 20px;
        font-weight: 700;
        background: none;

        &::placeholder {
          color: #162b4d;
          width: fit-content;
          font-size: 20px;
          font-weight: 700;
          text-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.05);
        }

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
          cursor: pointer;
        }

        &:active {
          &::placeholder {
            color: #162b4d;
          }
        }

        &:focus {
          background-color: white;
          color: #162b4d;

          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }
      }
    }

    .Link {
      text-decoration: none;
      width: auto;

      .homeButton {
        @include menuButton(dark);
        margin: 4px;
        background-color: rgba($color: #000000, $alpha: 0.05);
        border-radius: 4px;
        color: white;
        font-weight: 600;
        width: 32px;
        height: 32px;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.15);
        }

        &::before {
          content: url("../../img/home.png");
        }
      }
    }

    .boardMenuButton {
      @include menuButton(dark);
      width: auto;
      font-size: 14px;
      background-color: rgba($color: #000000, $alpha: 0.05);
      margin: 4px;
      padding-left: 16px;
      padding-right: 16px;
      color: #0e1c33;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.15);
      }

      &::before {
        content: none;
      }
    }
  }

  .Lists {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    .addListForm {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      height: 114px;

      .addList {
        @include hidden_input_focus;
        height: 48px;
        width: 240px;
        border-radius: 4px;
        background-color: rgba($color: black, $alpha: 0.09);
        color: #162b4d;
        padding: 24px;
        font-size: 16px;
        font-weight: 500;
        margin: 8px 4px;

        &::placeholder {
          color: #162b4d;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
