.Modals {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.2);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
