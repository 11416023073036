.ProfileButton {
  position: fixed;
  top: 4px;
  right: 16px;
  height: 48px;
  width: 48px;
  z-index: 99;
  border-radius: 50%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff8c;
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #0b52cc, $alpha: 0.2);
  }
}
