@import "../../sass/formatting.scss";
@import "../../sass/inputs.scss";

.CardMenu {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  .updateCardContainer {
    border-radius: 4px;
    padding: 8px;
    background: white;
    display: flex;
    flex-direction: column;
    width: 360px;
    height: auto;
    justify-content: flex-start;
    align-items: center;

    .updateCardForm {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      padding: 8px;
      max-height: 100vh;

      .buttonsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 0 16px;
        margin-top: 20px;

        button {
          padding: 16px;
          width: 136px;
          border: none;
          background: rgba($color: #000000, $alpha: 0.05);
          border-radius: 2px;
          color: black;
          font-weight: 700;

          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
            cursor: pointer;
          }
        }

        .saveButton {
          background-color: #61be4f;
          color: white;

          &:hover {
            background-color: #6ac957;
          }
        }
      }

      .labelsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        margin-top: -10px;
        position: relative;
        z-index: 99;

        .Label {
          margin-right: 4px;
          padding: 8px 8px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 800;
          color: white;
        }

        .labelsButton {
          @include plusButton();
          height: 32px;
          width: 32px;
        }
      }

      .cardMenuHeader {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        .cardMenuHeaderInputs {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;

          .updateCardName {
            font-size: 16px;
            @include hidden_input_focus;
            padding: 4px;
            width: 100%;
            resize: none;

            &:hover {
              background: none;
              cursor: pointer;
            }
          }
          .exitButton {
            @include exitButton();
            margin: 0 4px;
            height: 30px;
            width: 30px;
          }
        }
        .listsContainer {
          font-size: 12px;
          color: gray;
          position: relative;
          margin-left: 8px;

          select {
            border: none;
            background: none;
            text-decoration: underline;
            margin: 0 4px 8px 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: "";

            &:focus {
              outline: none;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .editDetails {
        height: auto;
        resize: none;
        width: 100%;
        margin-bottom: 16px;
        margin-top: -10px;
        border-radius: 2px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        padding: 8px;
      }

      .dueDate {
        margin-bottom: 16px;
      }

      .assigned_created_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .assignedTo {
          margin: 0;
          font-size: 12px;
          color: gray;
          margin-left: 4px;
          position: relative;

          select {
            border: none;
            background: none;
            text-decoration: underline;
            margin: 0 4px 8px 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: "";

            &:focus {
              outline: none;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .createdBy {
        margin-bottom: 16px;
        border: none;
        font-size: 12px;
        color: gray;

        &::before {
          content: "created by ";
        }

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: default;
        }
      }
    }
  }
}
