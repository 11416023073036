@import "../../sass/inputs.scss";

.thumbnailContainer {
  height: 96px;
  width: 200px;
  margin: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 6px rgba($color: #000000, $alpha: 0.1);

  .BoardThumbnail {
    border-radius: 4px;
    font-weight: 900;
    padding: 8px 0px 8px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    width: 100%;
    height: 100%;
    line-height: 24px;
    text-shadow: 0 0 24px rgba($color: #000000, $alpha: 0.5);
    text-align: left;

    &:hover {
      background: rgba($color: #ffffff, $alpha: 0.2);
      cursor: pointer;
    }

    .menuButton {
      @include menuButton(light);
      margin: 0 8px;
      margin-left: auto;
    }

    .unarchiveButton {
      justify-self: flex-end;
      margin-left: auto;
      @include menuButton(light);

      &::before {
        content: none;
      }
      letter-spacing: normal;
      width: auto;
      margin-right: 4px;
    }
  }

  .updateBoardContainer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .updateBoard {
      border-radius: 4px;
      padding: 12px 8px;
      background: white;
      display: flex;
      flex-direction: column;
      width: 360px;
      height: auto;
      justify-content: flex-start;
      align-items: center;

      .colorPickerContainer {
        width: 320px;
        margin-bottom: 24px;

        .colorPickerOptionsContainer {
          width: 100%;
          display: grid;
          justify-content: space-between;
          align-items: center;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

          .colorPickerLabel {
            height: 36px;
            width: 54px;
            margin: 4px 4px;
            border-radius: 4px;
            padding-top: 8px;
            padding-left: 12px;

            .colorPickerInput {
              top: 0;
              left: 0;
              opacity: 0;
              height: 0px;
              width: 0px;

              &:checked ~ span {
                &::before {
                  content: "\02714";
                  color: black;
                  opacity: 0.3;
                  padding: 4px;
                  font-size: 18px;
                }
              }
            }

            .colorPickerCustom {
              height: 100%;
              width: 100%;
              background: transparent;
            }
          }
        }
      }
      input {
        height: 40px;
        width: 320px;
        padding-left: 8px;
        font-weight: 700;
        border: 1px gray solid;
        border-radius: 4px;
        margin-bottom: 8px;
      }

      textarea {
        width: 320px;
        padding: 8px 4px 4px 4px;
        resize: none;
        border: 1px gray solid;
        border-radius: 4px;
        margin-bottom: 20px;
      }

      .buttonsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 0 16px;
        margin-bottom: 12px;

        button {
          padding: 16px;
          width: 88px;
          border: none;
          background-color: #61be4f;
          border-radius: 4px;
          color: white;
          font-weight: 700;

          &:hover {
            background-color: #6ac957;
            cursor: pointer;
          }
        }
      }
    }
  }
}
