@import "../../sass/inputs.scss";

.EditProfileMenu_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: rgba($color: #000000, $alpha: 0.05);
  padding: 12px 6px;

  .form_label {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 6px;
    padding: 6px;
    color: rgba($color: #000000, $alpha: 0.8);
    font-size: 12px;

    .form_input {
      height: 36px;
      width: 100%;
      padding: 8px;
      margin-top: 4px;
      border-radius: 2px;
      border: 1px rgba($color: #000000, $alpha: 0.2) solid;
    }
  }

  .form_buttons_container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 12px;

    button {
      @include modal_button();
    }

    .delete_account_button {
      background-color: rgba($color: #000000, $alpha: 0.2);
      height: 100%;

      &:hover {
        background-color: #ff5039;
      }
    }
  }
}
