@import "../../sass/formatting.scss";
@import "../../sass/inputs.scss";

.CollaboratorsMenu {
  width: 100%;
  height: auto;
  list-style: none;
  padding: 12px 6px;
  background: rgba($color: #000000, $alpha: 0.05);

  .collabs_container {
    margin: 12px 0;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .noCollabs {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba($color: #000000, $alpha: 0.2);
    }

    .collaborator {
      margin: 0 6px;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }

    .collaborator_removed {
      margin: 0 6px;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: rgba($color: #ff9688, $alpha: 1);
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "\2716";
      }
    }
  }

  .search_container {
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // padding: 12px;

    .search_input {
      height: 36px;
      border: 1px rgba($color: #000000, $alpha: 0.2) solid;
      border-radius: 2px;
      padding: 6px;
    }

    .search_ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      list-style: none;
      padding: 0;
      margin-top: 12px;
      overflow: scroll;
    }
  }

  .search_buttons_li {
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;

    button {
      @include modal_button();
      width: auto;
      margin-left: 4px;
    }
  }
}
