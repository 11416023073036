@import "../../sass/formatting.scss";
@import "../../sass/inputs.scss";

.BoardMenuContainer {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 8px 0;
  overflow: scroll;

  .BoardMenu {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 0;
    padding: 0px 0 10px 0px;
    background: white;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    list-style: none;
    overflow: scroll;

    hr {
      width: 100%;
      height: 1px;
      background-color: gray;
      border: none;
      margin: 0;
    }

    .menuHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-left: 20px;

      h3 {
        text-align: right;
        width: 100%;
        padding: 0;
      }
      .exitButton {
        @include exitButton(2em);
        margin: 0 8px;
      }
    }

    .MenuOption {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: right;
      width: 100%;
      height: 48px;
      padding: 16px 20px;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
        cursor: pointer;
      }
    }
  }
}
