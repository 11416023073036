/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  text-align: center;
  background-color: white;
  margin-top: 0;
}
