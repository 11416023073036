@mixin hidden_input_focus {
  border: none;
  border-radius: 2px;
  font-family: "Muli", sans-serif;
  background: none;

  &:hover {
    cursor: pointer;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  &:focus {
    border: 1px solid gray;
    background-color: rgba($color: #ffffff, $alpha: 1);
  }
}

@mixin modal_button($color: #61be4f, $hoverColor: #6ac957) {
  padding: 15px;
  width: 90px;
  border: none;
  background-color: $color;
  border-radius: 3px;
  color: white;
  font-weight: 700;

  &:hover {
    background-color: $hoverColor;
    cursor: pointer;
  }
}

@mixin menuButton($theme) {
  &::before {
    content: "\00A0\00B7\00A0\00B7\00A0\00B7\00A0";
    font-weight: 900;
  }

  height: 30px;
  width: 30px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: 700;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @if ($theme == "light") {
    color: white;

    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 0.4);
      cursor: pointer;
    }

    &:active {
      background-color: rgba($color: #ffffff, $alpha: 0.1);
    }
  } @else if ($theme == "dark") {
    color: #162b4d;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
      cursor: pointer;
    }

    &:active {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }
}

@mixin plusButton($size: 2em) {
  background: rgba($color: #000000, $alpha: 0.05);
  border: none;
  border-radius: 4px;
  line-height: $size;

  &::before {
    content: "\0002B";
    font-size: $size;
    color: gray;
  }

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);
    cursor: pointer;

    &::before {
      color: gray;
    }
  }
}

@mixin exitButton($size: 2em) {
  background: none;
  border: none;
  border-radius: 4px;

  &::before {
    content: "\2716";
    font-size: $size;
    color: black;
  }

  &:hover {
    background-color: #ff9688;
    cursor: pointer;

    &::before {
      color: white;
    }
  }

  &:active {
    background-color: #ff6f5c;
  }
}
