@import "../../sass/inputs.scss";

.ArchivedItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.05);

  .menuContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .cardsListsToggleButton {
      @include modal_button();
      margin-top: 8px;
      width: auto;
      margin-left: 24px;
      margin-right: 8px;
    }
  }

  .itemsList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 24px;
    background-color: #ebecf0;

    .archivedContainer {
      height: 48px;
      width: 100%;
      margin: 8px;
      border-radius: 4px;
      box-shadow: 0 1px 0 0 rgba($color: #000000, $alpha: 0.05);
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;

      & > p {
        font-size: 12px;
      }

      .unarchiveButton {
        @include menuButton(dark);
        width: auto;

        &::before {
          content: "";
        }
      }
    }
  }
}
