@import "../../sass/inputs.scss";
@import "../../sass/formatting.scss";

.BoardsList {
  height: 100%;
  width: 100%;
  // position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 48px;

  h2 {
    margin-bottom: 0;
  }
  .noArchivedBoardsMessage {
    height: 200px;
    background-color: rgba($color: #000000, $alpha: 0.03);
    text-align: center;
    line-height: 200px;
    color: gray;
    margin: 20px;
    width: 97%;
    border-radius: 4px;
  }

  .BoardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;

    .container {
      height: 96px;
      width: 200px;
      margin: 8px;
      border-radius: 4px;
      background-color: rgba($color: rgb(238, 238, 238), $alpha: 1);

      .BoardThumbnail {
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        color: rgb(78, 78, 78);
        height: 100%;
        width: 100%;
        border-radius: 4px;

        &:hover {
          background: rgba($color: #000000, $alpha: 0.1);
          cursor: pointer;
        }
      }
    }
  }

  .showArchivedButton {
    padding: 16px 24px;
    border: none;
    background-color: #eb5946;
    border-radius: 4px;
    color: white;
    font-weight: 700;
    margin-bottom: 48px;

    &:hover {
      background-color: #f76451;
      cursor: pointer;
    }
  }
}
